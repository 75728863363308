import React, { Component } from 'react';
import { auth } from '../../Firebase';

import Login from './Login';
import TicketList from './TicketList';
import Ticket from './Ticket';
import CreateTicket from './CreateTicket';

export default class SupportDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      ticketId: null,
      mode: null,
    };
  }

  getContent() {
    if (this.state.mode) {
      switch (this.state.mode) {
        case 'create-ticket':
          return <CreateTicket />;
        case 'ticket':
          return <Ticket ticketId={this.state.ticketId} userId={this.state.user.uid} />;
        default:
          return <TicketList user={this.state.user} />;
      }
    } else {
      return <TicketList user={this.state.user} />;
    }
  }

  componentDidUpdate(prevProps) {
    // Ensure match and params exist before accessing them
    if (this.props.match && this.props.match.params) {
      const mode = this.props.match.params.mode;

      if (mode) {
        switch (mode) {
          case 'ticket':
            const ticketId = this.props.match.params.ticketId;
            if (ticketId) {
              if (ticketId !== this.state.ticketId) {
                this.setState({
                  mode: mode,
                  ticketId: ticketId,
                });
              }
            } else {
              if (this.state.ticketId) {
                this.setState({
                  mode: null,
                  ticketId: null,
                });
              }
            }
            break;
          case 'create-ticket':
            if (this.state.mode !== mode) {
              this.setState({
                mode: mode,
                ticketId: null,
              });
            }
            break;
          default:
            break;
        }
      } else {
        const state = {};
        if (this.state.mode) {
          state.mode = null;
        }
        if (this.state.ticketId) {
          state.ticketId = null;
        }
        if (Object.keys(state).length > 0) {
          this.setState(state);
        }
      }
    }
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      let currentUser = null;

      if (user && user.emailVerified) {
        currentUser = user;
      }

      this.setState({ user: currentUser });
    });
  }

  render() {
    const user = this.state.user;

    return user ? this.getContent() : <Login />;
  }
}