import React, {useState} from "react";
import {Helmet} from 'react-helmet'
import {functions} from '../../Firebase';
import TextareaAutosize from 'react-textarea-autosize';
import {Spinner, Message, displaySpinner, displayMessage} from '../ModalComponents/Spinner.js';
import {useNavigate} from 'react-router-dom';


export default function CreateTicket(){
  const history = useNavigate();

  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
      e.preventDefault();
      //Validate

      //Structure Data
      let docData = {
        reason: reason,
        message: message
      }
      displaySpinner(true);

      var createSupportTicket = functions.httpsCallable("createSupportTicket");
      createSupportTicket(docData).then((data) => {

        displaySpinner(false);
        history.push(`/support/ticket/${data.data}`)

      }).catch((error) => {

        displaySpinner(false);
        displayMessage(true, false, `An error occured: ${error.message}`)

      });
  }

  return(
    <div className="home">
      <Helmet>
        <title>Trippie Air - Create a new ticket</title>
        <meta name="description" content="Trippie Air" />
      </Helmet>
      <Spinner/>
      <Message/>
      <div className="home-hero bg-gradient">
        <div className="container-middle-content">
          <div className="about-header-text">
            <h1 className="medium-heading">Contact us</h1>
            <p className="medium-para">
              Need a bit extra help, submit a support ticket and we will get
              back to you as soon as we can.
            </p>
          </div>
        </div>
      </div>
      <div className="container-middle-content">
        <div className="contact-form-box">
          <form className="contact-form" onSubmit={handleSubmit}>
            <div>
            <h1 className="small-heading">Contact Form</h1>
            <div className="form-input">
              <label>Reason for contact<span style={{ color: 'red' }}> * </span></label>
              <select
                required={true}
                name="contactReason"
                value={reason}
                onChange={e => setReason(e.target.value)}>
                <option disabled={true} value="">---Select Option---</option>
                <option value="General">General Enquiry</option>
                <option value="Incident">Incident Report</option>
                <option value="Deactivation">Account Deactivation</option>
              </select>
            </div>
            <div className="form-input">
              <label>Message <span style={{ color: 'red' }}> *<i style={{ fontSize: '12px'}}>must be at least 10 characters</i></span></label>
              <TextareaAutosize
                required={true}
                type="text"
                placeholder="Write a message..."
                name="message"
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            </div>
            <div>
              <span style={{ color: 'red' }}> * <i style={{ fontSize: '12px' }}>Field Required</i></span>
            </div>
            <input className="standard-button" type="submit" value="Submit" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
