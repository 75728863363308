import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import '../App.min.css';

import Navigation from '../components/navigation/Navigation.js';
import Home from '../components/mainComponents/Home.js';
import About from '../components/mainComponents/About';
import Faq from '../components/mainComponents/FAQ';
import Legal from '../components/mainComponents/Legal';
import Register from '../components/mainComponents/Register';
import SupportDashboard from '../components/mainComponents/SupportComponents/SupportDashboard';

export default function App() {
  return (
    <Router>
    <Navigation/>
      <>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/faqs" element={<Faq />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/register" element={<Register />} />
          <Route exact path="/support" element={<SupportDashboard />} />
          <Route exact path="/support/:mode" element={<SupportDashboard />} />
          <Route path="/support/:mode/:ticketId" element={<SupportDashboard />} />
        </Routes>
        <div className="footer-container">
          <div className="container-middle-content footer">
            Copyright © 2020 Trippie | Powered by&nbsp; <a className="developer-link" rel="noreferrer" href="http://jamesdwhite.me/" target="_blank"> James White</a>
          </div>
        </div>
      </>
    </Router>
  );
}
